<template>
    <v-container fluid>
        <v-progress-linear v-if="loading" indeterminate />
        <v-row no-gutters>
            <v-col cols="12" xl="3" lg="3" md="3">
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                            class="py-0 my-0"
                            v-model="dateRangeText"
                            color="primary"
                            label="Date range"
                            readonly
                            flat
                            solo
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                        ></v-combobox>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        range
                        scrollable
                        :min="minDate"
                    >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            :loading="loading"
                            @click="loadData"
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-4 mb-8">
            <v-col
                cols="12"
                lg="2"
                md="2"
                sm="2"
                v-for="(insight, index) of insights"
                :key="index"
            >
                <v-card class="pt-2 px-2 ml-2">
                    <v-card-text
                        class="d-flex my-0 py-0 mx-0 px-0"
                        width="100%"
                    >
                        <v-chip
                            class="mb-1 mt-0 mx-1 pt-0 white--text"
                            :color="insight.color"
                            label
                            style="height: 50px"
                        >
                            <v-icon center>
                                mdi-cart
                            </v-icon>
                        </v-chip>
                        <div class="mb-1 mt-0 pt-0">
                            <div class="d-flex mr-0 pr-0 text-capitalize">
                                <span>{{ insight.title }}</span>
                            </div>
                            <p class="text-h6 black--text">
                                {{ insight.value }}
                            </p>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12">
                <Highcharts :options="chartColumn" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { Chart } from 'highcharts-vue'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
    name: 'PurchasesStatistics',
    components: {
        Highcharts: Chart,
    },
    data: () => ({
        changelog: [],
        menu: false,
        insights: [
            {
                value: 0,
                color: '#3498DB',
                iconColor: '#1A5276',
                title: 'period total',
            },
            {
                value: 0,
                color: '#5DADE2',
                iconColor: '#2874A6',
                title: 'period average',
            },
            {
                value: 0,
                color: '#273746',
                iconColor: '#2874A6',
                title: 'period item total',
            },
            {
                value: 0,
                color: '#566573',
                iconColor: '#808B96',
                title: 'period item average',
            },
            {
                value: 0,
                color: '#1D8348',
                iconColor: '#0B5345',
                title: 'period item av. / purchase',
            },
            {
                value: 0,
                color: '#ffbd00',
                iconColor: '#0B5345',
                title: 'Pending / average',
            },
        ],
        loading: true,
        chartColumn: {},
        purchasesResults: [],
        startDate: '',
        endDate: '',
        dates: [],
        dateRanges: [],
        minDate: moment()
            .subtract(3, 'months')
            .format('YYYY-MM-DD'),
    }),
    async created() {
        this.chartColumn = {
            chart: {
                type: 'spline',
            },
            title: {
                text: '',
            },
            xAxis: {
                categories: [],
            },
            yAxis: {
                title: {
                    text: '',
                },
            },
            series: [],
            credits: {
                enabled: false,
            },
            accessibility: { enabled: false },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
        }
    },
    async mounted() {
        try {
            this.loading = true
            this.initDates()
            this.purchasesResults = await API.getPurchasesInform({
                startDate: this.startDate,
                endDate: this.endDate,
            })
            this.dateRanges = this.daysBetweenDates(
                moment(this.startDate),
                moment(this.endDate)
            )
            this.printData()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ')
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        daysBetweenDates(startDate, endDate) {
            this.datesObject = []
            let currentDay = startDate
            let dates = []
            while (currentDay.isSameOrBefore(endDate)) {
                dates.push(currentDay.format('YYYY-MM-DD'))
                this.datesObject[currentDay.format('YYYY-MM-DD')] = 0
                currentDay.add(1, 'days')
            }
            dates.push(endDate.format('YYYY-MM-DD'))
            return dates
        },
        async loadData() {
            try {
                this.loading = true
                if (this.dates[0] > this.dates[1]) {
                    this.startDate = this.dates[1]
                    this.endDate = this.dates[0]
                } else {
                    this.startDate = this.dates[0]
                    this.endDate = this.dates[1]
                }
                this.purchasesResults = await API.getPurchasesInform({
                    startDate: this.startDate,
                    endDate: this.endDate,
                })
                // set days
                this.dateRanges = this.daysBetweenDates(
                    moment(this.startDate),
                    moment(this.endDate)
                )
                this.printData()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        loadInsights(result) {
            // clean insights
            this.insights[0].value = 0
            this.insights[1].value = 0
            this.insights[2].value = 0
            this.insights[3].value = 0
            this.insights[4].value = 0
            this.insights[5].value = 0
            // set total purchases and total items
            for (const key of Object.keys(result)) {
                this.insights[0].value += result[key][0]
                this.insights[2].value += result[key][1]
            }
            // set averages
            this.insights[1].value =
                Object.keys(result).length == 0
                    ? 0
                    : Math.ceil(
                          this.insights[0].value /
                              Object.keys(result).filter(
                                  key => result[key][0] > 0
                              ).length
                      )
            this.insights[3].value =
                Object.keys(result).length == 0
                    ? 0
                    : Math.ceil(
                          this.insights[2].value /
                              Object.keys(result).filter(
                                  key => result[key][1] > 0
                              ).length
                      )
            this.insights[4].value =
                this.insights[0].value == 0
                    ? 0
                    : Number.parseFloat(
                          (
                              this.insights[2].value / this.insights[0].value
                          ).toFixed(1)
                      )
            const currentDate = new Date().toISOString().slice(0, 10)
            this.insights[5].value =
                !result[currentDate] ||
                result[currentDate][1] == 0 ||
                this.insights[1].value == 0
                    ? 0
                    : Number.parseFloat(
                          (
                              result[currentDate][1] / this.insights[1].value
                          ).toFixed(1)
                      )
        },
        async printData() {
            const sortDates = Object.keys(this.purchasesResults).sort()
            // load insights
            this.loadInsights(this.purchasesResults)
            // Configuración de Highcharts
            const categories = sortDates.filter(date => {
                if (date >= this.startDate && date <= this.endDate) {
                    return true
                }
            })
            this.chartColumn.xAxis.categories = categories
            this.chartColumn.tooltip = {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat:
                    '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
            }
            this.chartColumn.series = [
                {
                    name: 'requests',
                    data: categories.map(date => ({
                        name: date,
                        y: this.purchasesResults[date][0],
                    })),
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                    },
                },
                {
                    name: 'items',
                    data: categories.map(date => ({
                        name: date,
                        y: this.purchasesResults[date][1],
                    })),
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                    },
                },
                {
                    name: 'pending',
                    data: categories.map(date => ({
                        name: date,
                        y: this.purchasesResults[date][2],
                    })),
                    dataLabels: {
                        enabled: true,
                        format: '{y}',
                    },
                },
            ]
            this.menu = false
        },
        initDates() {
            const now = new Date().toISOString().substring(0, 10)
            let oneWeekAgo = new Date()
            oneWeekAgo = oneWeekAgo.toISOString().substring(0, 8) + '01'
            this.dates.push(oneWeekAgo)
            this.startDate = oneWeekAgo
            this.dates.push(now)
            this.endDate = now
        },
    },
}
</script>

<style></style>
